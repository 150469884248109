form {
  max-width: 100%;
  width: 100%;
  max-width: 600px; 
  margin: 0 auto; 
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 12%;
}
body{
  background-color: aliceblue;
}
label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
}

@media (max-width: 768px) {
  form {
    padding: 15px;
  }

  input, button {
    font-size: 14px;
  }

  button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  form {
    padding: 10px;
  }

  input, button {
    font-size: 12px;
  }
}
